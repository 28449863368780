import React from "react"
import styles from './styles.module.scss'
import CaliforniaUnivSrc from '../../../assets/img/icons/california_univ.png'
import DartmouthUnivSrc from '../../../assets/img/icons/dartmouth.png'
import CornellSrc from '../../../assets/img/icons/cornell.png'
import NorthWestUnivSrc from '../../../assets/img/icons/nor-wes_univ.png'
import DukeUnivSrc from '../../../assets/img/icons/duke_univ.png'
import HarvardUnivSrc from '../../../assets/img/icons/harvard.png'

const ReferencedByBlock = () => {
  
  const icons = {
    "California university": CaliforniaUnivSrc,
    "Dartmouth college": DartmouthUnivSrc,
    "Cornell university": CornellSrc,
    "Northwestern university": NorthWestUnivSrc,
    "Duke university": DukeUnivSrc,
    "Harvard university": HarvardUnivSrc,
  };
  
  return (
      <section className={styles.referencedByBlock}>
        <div className={styles.referencedByBlockContainer}>
          <h3 className={styles.title}>As referenced by</h3>
          
          <div className={styles.iconsWrapper}>
            {Object.keys(icons).map( (key, index) => (
                <img src={icons[key]} className={styles.icon} alt={key} key={index}/>
            ))}
          </div>
        </div>
      </section>
  )
};

export default ReferencedByBlock
