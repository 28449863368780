import React from 'react';
import {graphql, StaticQuery} from 'gatsby'
import Layout from "../../components/Layout";
import SEO from '../../components/seo';
import PropTypes from "prop-types";
import PromoBlocksSection from './PromoBlocksSection';
import ReferencedByBlock from './ReferencedByBlock';
import AreasOfInterestBlock from './AreasOfInterestBlock';
// import styles from './styles.module.scss';

const Homepage = ( {data} ) => {
  const homepage = data.landingPage;
  
  return (
      <Layout isHomepage={true}>
        <SEO title="Online Degree Programs & Online Schools" description="Find online degree programs & learn about earning a degree through online education. Accredited online schools & colleges. Including subject guides."/>
        
        <PromoBlocksSection blocks={homepage.promoBlocks} />
        
        <ReferencedByBlock />
        
        <AreasOfInterestBlock />
      </Layout>
  );
};

Homepage.propTypes = {
  data: PropTypes.object
};

export default () => (
  <StaticQuery
    query={graphql`
      query HomepageQuery {
        landingPage(path: {eq: "home"}) {
          type
          path
          title
          promoBlocks {
            image
            title
            body
            linkTitle
            linkPath
          }
        }
      }
    `}
    render={ (data) => <Homepage data={data} /> }
  />
)
